import { useCallback } from "react";
import "./Footer.css";
import { STRINGS } from "../Utility/StringsEn";

const Footer = () => {
  const onIconLinkedinClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/springct/");
  }, []);

  const onIconInstaClick = useCallback(() => {
    window.open("https://www.instagram.com/spring.ct/");
  }, []);

  const onIconTwitterClick = useCallback(() => {
    window.open("https://twitter.com/SpringCTI/");
  }, []);

  const onFbIconsContainerClick = useCallback(() => {
    window.open("https://www.facebook.com/SpringComputingTechnologies/");
  }, []);

  const onIconFBClick = useCallback(() => {
    window.location.href = "https://twitter.com/SpringCTI/";
  }, []);

  return (
    <div className="footer2">
      <div className="content8">
        <div className="text-logo3">
          <div className="an-offering-from4">{STRINGS.anOfferingFrom}</div>
          <div className="springctcontact3">
            <div className="logo4">
              <img
                className="ic-logo-w-1-icon3"
                alt=""
                src="/ic-logo-w-1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="text6">
          <div className="spring-computing-technologies-container1">
            {`© 2024 Spring Computing Technologies, `}
            <span className="all-rights-reserved1">All Rights Reserved.</span>
          </div>
        </div>
        <div className="socialmediaicons2">
          <div className="linkedin-icons2" onClick={onIconLinkedinClick}>
            <button className="iconfb8">
              <img className="group-icon16" alt="" src="/group.svg" />
            </button>
            <button className="iconfb-hover8">
              <img className="group-icon17" alt="" src="/group1.svg" />
              <div className="linkedin-hover-22">
                <img className="vector-icon16" alt="" src="/vector1.svg" />
                <img className="vector-icon17" alt="" src="/vector2.svg" />
              </div>
            </button>
          </div>
          <div className="insta-icons2" onClick={onIconInstaClick}>
            <button className="iconfb8">
              <img className="group-icon16" alt="" src="/group2.svg" />
            </button>
            <button className="iconfb-hover8">
              <img className="group-icon17" alt="" src="/group3.svg" />
              <img
                className="insta-hover-2-icon2"
                alt=""
                src="/insta-hover-2.svg"
              />
            </button>
          </div>
          <div className="fb-icons2" onClick={onFbIconsContainerClick}>
            <button className="iconfb8">
              <img className="group-icon16" alt="" src="/group4.svg" />
            </button>
            <button className="iconfb-hover8">
              <img className="group-icon17" alt="" src="/group5.svg" />
              <img
                className="insta-hover-2-icon2"
                alt=""
                src="/facebook-hover-2.svg"
              />
            </button>
          </div>
          <div className="twitter-icons2" onClick={onIconTwitterClick}>
            <button className="iconfb8" onClick={onIconFBClick}>
              <img className="group-icon22" alt="" src="/group6@2x.png" />
            </button>
            <button className="iconfb-hover8">
              <img className="group-icon23" alt="" src="/group7@2x.png" />
              <img
                className="twitter-hover-2-icon2"
                alt=""
                src="/twitter-hover-2@2x.png"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
